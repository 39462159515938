<!--
 * @Author: LXG
 * @Date: 2021-05-14
 * @Editors: LXG
 * @LastEditTime: 2021-06-27
 * @Description: 政策办事指南内容
-->
<template>
  <div class="policy-guide-content">
    <Anchor show-ink>
      <AnchorLink
        v-for="(item, index) in anchorList"
        :key="index"
        :href="'#' + item.href"
        :title="item.title"
      >
      </AnchorLink>
    </Anchor>
    <div class="particular">
      <div id="jbxx" class="pgc-item">
        <h2 class="pgc-title">基本信息</h2>
        <el-divider class="divider"></el-divider>
        <TwoColumnTable
          :items="cpu_baseList"
          labelWidth="160px"
        ></TwoColumnTable>
        <div v-show="buttonType == '2'">
          <h4 class="pgc-title">扩展信息</h4>
          <TwoColumnTable
            :items="extendList"
            labelWidth="160px"
          ></TwoColumnTable>
          <h4 class="pgc-title">编码信息</h4>
          <TwoColumnTable :items="baseList" labelWidth="160px"></TwoColumnTable>
        </div>
        <button
          v-text="buttonType == '1' ? '显示更多' : '收起'"
          class="typeBtn"
          @click="showMore"
        ></button>
      </div>
      <div id="sqtj" class="pgc-item">
        <h2 class="pgc-title">申请条件</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content">
          <!-- <p>
            1.在发展文化产业方面做出显著成绩，在全省同类企业中具有典型和示范意义；
          </p>
          <p>
            2.企业发展符合中央和省委、省政府关于发展文化产业政策的相关要求，具有较好的成长性；
          </p>
          <p>
            3.企业生产的文化产品和提供的文化服务能够面向市场、面向群众，有核心产品和自主品牌，有较大生产规模，社会效益和经济效益显著；
          </p>
          <p>
            4.具备高素质的管理团队和科学规范的内部管理制度，具有较强的自主创新精神和市场开拓能力。
          </p> -->
          <p>{{ matterDetailsObj.preService.applyConditionDesc }}</p>
        </div>
      </div>
      <div id="sdyj" class="pgc-item">
        <h2 class="pgc-title">设定依据</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content particular-sblc">
          <p>
            {{ matterDetailsObj.preService.legalStandard }}
          </p>
        </div>
      </div>
      <div id="sqcl" class="pgc-item">
        <h2 class="pgc-title">申请材料</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content particular-sblc">
          <h2>无需提交申请材料</h2>
        </div>
      </div>
      <div id="sfxx" class="pgc-item">
        <h2 class="pgc-title">收费信息</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content particular-sblc">
          <!-- <h2>不收费</h2> -->
          <h2>{{ matterDetailsObj.preServiceExt.chargeStandard }}</h2>
        </div>
      </div>
      <div id="bllc" class="pgc-item">
        <h2 class="pgc-title">办理流程</h2>
        <el-divider class="divider"></el-divider>
        <!-- <div
          class="text-i-28 particular-content"
          v-html="cpu_noText(policyBaseData.supportstandard)"
        ></div> -->
        <div style="min-height: 600px">
          <el-steps direction="vertical" :active="stepList.length">
            <el-step v-for="(item, index) in stepList" :key="index">
              <template slot="title">
                <el-row>
                  <el-col :span="12" align="left">{{ item.title }}</el-col>
                  <el-col :span="12" align="right" style="color: #a5a4a4">
                    <span>时限：{{ item.time }}</span>
                    <span>审批人：{{ item.person }}</span>
                  </el-col>
                </el-row>
              </template>
              <template slot="description">
                <el-row class="desTitle">
                  <span style="font-weight: 600; color: #333">办理结果：</span>
                  <span style="color: #333">{{ item.result }}</span>
                </el-row>
                <el-row class="desCont">
                  <h4>审查标准</h4>
                  <p>{{ item.content }}</p>
                </el-row>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
      <div id="spjg" class="pgc-item">
        <h2 class="pgc-title">审批结果</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content">
          <el-table :data="tableData" stripe border size="medium">
            <el-table-column
              prop="index"
              label="序列"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="resultName"
              label="结果名称"
              width="200px"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.zzxe"> {{ scope.row.zzxe }}万元 </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="resultExample"
              label="结果样本"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="resultType"
              label="结果类型"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="resultDesc"
              label="领取说明"
              header-align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import TjIcon from '@/components/tjIcon/TjIcon'
import TwoColumnTable from "@/components/twoColumnTable";
import { formatDate } from "@/utils/dateHandler.js";
import variableStyle from "@/styles/variable.scss";

export default {
  name: "PolicyGuideContent",
  components: {
    // TjIcon,
    TwoColumnTable,
  },
  props: {
    matterUnid: {
      type: String,
    },
    matterObj: {
      type: Object,
    },
    matterDetailsObj: {
      type: Object,
    },
  },
  data() {
    return {
      variableStyle: variableStyle,
      policyBaseData: {}, // 政策基本信息
      policyZzbzData: [], // 政策资助标准信息(可能多个资助标准)
      qxId: "", // 同一个政策不同资助标准的唯一ID 资助ID?
      policyPortraitData: {}, // 政策画像信息
      anchorList: [
        { href: "jbxx", title: "基本信息" },
        { href: "sqtj", title: "申请条件" },
        { href: "sdyj", title: "设定依据" },
        { href: "sqcl", title: "申请材料" },
        { href: "sfxx", title: "收费信息" },
        { href: "bllc", title: "办理流程" },
        { href: "spjg", title: "审批结果" },
      ],
      tableData: [],
      stepList: [],
      buttonType: "1",
    };
  },
  computed: {
    /**
     * @description: 基本信息列表
     * @param {*}
     * @return {*}
     */
    cpu_baseList() {
      let list = [
        {
          title: "事项名称",
          content: this.matterDetailsObj.preService.serviceName,
        },
        {
          title: "事项类型",
          content: this.matterDetailsObj.preService.serviceType,
        },
        {
          title: "实施主体",
          content: this.matterDetailsObj.preService.deptName,
        },
        {
          title: "办件类型",
          content: this.matterDetailsObj.preService.infoType,
        },
        {
          title: "法定办理时限",
          content: `${this.matterDetailsObj.preService.legalDays}个工作日`,
        },
        {
          title: "承诺办理时限",
          content: `${this.matterDetailsObj.preService.promiseDays}个工作日`,
        },
        {
          title: "权利来源",
          content: this.matterDetailsObj.preServiceExt.powerResource,
        },
        {
          title: "行使层级",
          content: this.matterDetailsObj.preService.performLevel,
        },
        {
          title: "是否涉及特殊环节",
          content: this.matterDetailsObj.preServiceExt.specialProcesse,
        },
        {
          title: "是否涉及中介服务",
          content: this.matterDetailsObj.preService.mediationServices,
        },
        {
          title: "实施主体性质",
          content: this.matterDetailsObj.preService.implementPropety,
        },
        {
          title: "服务对象",
          content: this.matterDetailsObj.preService.forUser,
        },
        {
          title: "是否网办",
          content: this.matterDetailsObj.preServiceExt.handleType,
        },
        {
          title: "办理形式",
          content: this.matterDetailsObj.preServiceExt.handleType,
        },
        {
          title: "网上办理深度",
          content: this.matterDetailsObj.preServiceExt.onlinehandledepth,
        },
        {
          title: "通办范围",
          content: this.matterDetailsObj.preService.handleScope,
        },
        {
          title: "数量限制",
          content: this.matterDetailsObj.preService.countLimitDesc,
        },
        {
          title: "四办标志",
          content: this.matterDetailsObj.preServiceExt.fourStandard,
        },
        {
          title: "最多到现场次数",
          content: this.matterDetailsObj.preServiceExt.towinmaxnum,
        },
        {
          title: "必须到现场办理原因说明",
          content: this.matterDetailsObj.preServiceExt.towininfo,
        },
        {
          title: "是否支持物流快递",
          content: this.matterDetailsObj.preServiceExt.isExpress,
        },
        {
          title: "是否网上支付",
          content: this.matterDetailsObj.preServiceExt.isOnlinePay,
        },
        {
          title: "行使内容",
          content: this.matterDetailsObj.preService.performContent,
        },
        {
          title: "权限划分",
          content: this.matterDetailsObj.preService.permissionsDivision,
        },
      ];

      return list;
    },
    extendList() {
      let list = [
        {
          title: "入驻网上办事大厅方式",
          content: this.matterDetailsObj.preService.applyType,
        },
        {
          title: "是否投资事项",
          content: this.matterDetailsObj.preService.sftzsx,
        },
        {
          title: "是否支持预约办理",
          content: this.matterDetailsObj.preServiceExt.isReservation,
        },
        {
          title: "是否进驻政务实体大厅",
          content: this.matterDetailsObj.preServiceExt.isentrycenter,
        },
        {
          title: "个人主题分类",
          content: this.matterDetailsObj.preService.personZt,
        },
        {
          title: "是否支持自助终端处理",
          content: this.matterDetailsObj.preServiceExt.isserviceterminals,
        },
        {
          title: "面向自然人的事件分类（人生事件）",
          content: this.matterDetailsObj.preService.personRssj,
        },
        {
          title: "法人主题分类",
          content: this.matterDetailsObj.preService.enterpriseZt,
        },
        {
          title: "面向法人的特定对象分类",
          content: this.matterDetailsObj.preService.enterpriseTddx,
        },
        {
          title: "面向自然人的特定人群分类",
          content: this.matterDetailsObj.preService.personTdrq,
        },
        {
          title: "面向法人的经营活动分类",
          content: this.matterDetailsObj.preService.enterpriseJyhd,
        },
        {
          title: "办理地址",
          content: this.matterDetailsObj.preService.acceptAddrDesc,
        },
        {
          title: "窗口描述",
          content: this.matterDetailsObj.preService.winDesc,
        },
        {
          title: "交通指引",
          content: this.matterDetailsObj.preService.trafficGuide,
        },
        {
          title: "运行系统名称",
          content: this.matterDetailsObj.preService.runSystem,
        },
        {
          title: "地图坐标",
          content: this.matterDetailsObj.preService.mapPosition,
        },
        {
          title: "办理系统咨询电话",
          content: this.matterDetailsObj.preService.contactPhone,
        },
        {
          title: "监督投诉电话",
          content: this.matterDetailsObj.preService.monitorComplain,
        },
      ];
      return list;
    },
    baseList() {
      let list = [
        {
          title: "实施主体编码",
          content: this.matterDetailsObj.preService.implementCode,
        }, //只有实施编码
        {
          title: "实施编码",
          content: this.matterDetailsObj.preService.implementCode,
        },
        { title: "地方实施编码", content: "123456798" },
        {
          title: "业务办理项编码",
          content: this.matterDetailsObj.preServiceExt.taskhandleitem,
        },
      ];
      return list;
    },
  },
  methods: {
    showMore() {
      this.buttonType = this.buttonType == "1" ? "2" : "1";
    },
  },
  created() {
    let handleProcess = this.matterDetailsObj.preService.flowDesc.split("\n");
    // 拼装办理流程数据
    handleProcess.forEach((item) => {
      let amongList = item.split("；");
      let obj = {
        title: amongList[0].substring(5),
        time: amongList[2].substring(5),
        person: amongList[1].substring(4),
        result: amongList[4].substring(5),
        content: amongList[3].substring(5),
      };
      this.stepList.push(obj);
    });
    // 拼装审批结果信息
    let resultCode =
      this.matterDetailsObj.preServiceExt.resultCode.split("@@@");
    let resultDesc =
      this.matterDetailsObj.preServiceExt.resultDesc.split("@@@");
    let resultExample =
      this.matterDetailsObj.preServiceExt.resultExample.split("@@@");
    let resultGettype =
      this.matterDetailsObj.preServiceExt.resultGettype.split("@@@");
    let resultName = this.matterDetailsObj.preServiceExt.resultName.split("/");
    let resultType =
      this.matterDetailsObj.preServiceExt.resultType.split("@@@");
    for (let i = 0; i < resultName.length; i++) {
      let tableObj = {
        index: i,
        resultCode: resultCode[i],
        resultDesc: resultDesc[i],
        resultExample: resultExample[i],
        resultGettype: resultGettype[i],
        resultName: resultName[i],
        resultType: resultType[i],
      };
      this.tableData.push(tableObj);
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.policy-guide-content {
  position: relative;
}
.pgc-item + .pgc-item {
  margin-top: 40px;
}
.pgc-title {
  margin-bottom: 10px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
}
.easyItem-content {
  line-height: 2;
  text-indent: 28px;
}
.particular-content {
  line-height: 2;
  white-space: pre-wrap;
}
.text-i-28 {
  text-indent: 28px;
}
::v-deep .easy-zcnr-table {
  .table-sbdx-header {
    th {
      font-weight: normal;
      color: #ffffff;
      background-color: $col-theme;
    }
  }
  .table-sbdx-row {
    .table-sbdx-cell-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .el-button {
        padding: 5px;
      }
      // .tj-icon {
      //     font-size: 20px;
      //     color: $col-theme;
      //     cursor: pointer;
      // }
    }
  }
}
.particular-sblc {
  ::v-deep img {
    width: 100%;
    max-width: 700px;
  }
}
.particular {
  ::v-deep .el-table {
    .el-table__header-wrapper {
      th {
        font-weight: normal;
        color: #000000;
        background-color: #f2f2f2;
      }
    }
  }
}

::v-deep .ivu-anchor {
  position: fixed;
  background-color: #ffffff;
  .ivu-anchor-ink {
    right: 0;
    left: auto;
  }
  .ivu-anchor-ink-ball {
    width: 4px;
    height: 20px;
    border: none;
    border-radius: 0;
    background-color: $col-theme;
    transform: translate(-50%, -2px);
  }
  .ivu-anchor-link {
    height: 40px;
    padding: 8px 16px 8px 0;

    &.ivu-anchor-link-active a {
      color: $col-theme;
    }
  }
}
@media screen and (min-width: 1366px) {
  ::v-deep .ivu-anchor {
    top: 26vh;
    left: calc(50% - 565px - 100px);
  }
}
@media screen and (min-width: 1600px) {
  ::v-deep .ivu-anchor {
    top: 26vh;
    left: calc(50% - 600px - 140px);
  }
}
@media screen and (min-width: 1920px) {
  ::v-deep .ivu-anchor {
    top: 26vh;
    left: calc(50% - 707px - 100px);
  }
}
@media screen and (max-width: 1365.98px) {
  ::v-deep .ivu-anchor {
    left: calc(50% + 512px - 100px);
    bottom: calc(#{$footerHeight} + 5vh);
  }
}
.divider {
  height: 4px;
  background-color: #333;
}
::v-deep .el-divider--horizontal {
  margin: 10px 0 24px 0;
}
::v-deep .el-step__description {
  padding-right: 0;
}
::v-deep .el-step__title.is-finish {
  color: #000;
}
.desTitle {
  font-size: 14px;
  margin: 16px auto;
}
.desCont {
  font-size: 14px;
  background-color: #f1f3f3;
  margin: 16px auto;
  h4 {
    font-weight: 600;
    color: #333;
    margin: 8px 0 4px 10px;
  }
  p {
    color: #333;
    margin: 8px 0 4px 10px;
  }
}
.typeBtn {
  width: 100%;
  background-color: #f1f2f3;
  text-align: center;
  font-size: 14px;
  height: 40px;
  margin-top: 15px;
  color: #2850a0 !important;
  cursor: pointer;
}
</style>
