<template>
  <div class="policy-guide">
    <div class="def-container wrap-header">
      <div class="header_bottom">
        <!-- <div class="guide-btns">
          <el-button type="primary">在线办理</el-button>
          <el-button class="btn-collect">
            <div class="collect_inner">
              <span class="collect-text"></span>
            </div>
          </el-button>
        </div> -->
        <img src="~@/assets/images/positionImg.png" alt="" style="">
             <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;display:inline-block;margin-left:8px">
          <el-breadcrumb-item :to="{ path: '/'}"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/enterpriseLibrary' }" v-if="navFlag==9"
            >企业办事库</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/searchList' }" v-if="navFlag==1"
            >分类搜索</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/onethingGuide' }" v-if="linkType"
            >场景指南</el-breadcrumb-item
          >
           <el-breadcrumb-item :to="{ path: '/serviceGuide' }"
            >办事指南</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <h1 class="policy-name">{{ matterDetailsObj.preService.serviceName }}</h1>
    </div>
    <div class="def-container wrap-content">
      <div class="content_left">
        <serviceGuideContent
          class="marg-tb-3"
          :matterUnid="matterUnid"
          :matterObj="matterObj"
          :matterDetailsObj="matterDetailsObj"
        ></serviceGuideContent>
      </div>
      <div class="content_right">
        <el-card class="guide-card card-text" shadow="never">
          <el-row>
            <el-col :span="12" align="center">
              {{ matterDetailsObj.preService.legalDays }}个工作日</el-col
            >
            <el-col :span="12" align="center"
              >{{ matterDetailsObj.preService.promiseDays }}个工作日</el-col
            >
          </el-row>
          <el-row>
            <el-col :span="12" align="center"> 法定办结时限</el-col>
            <el-col :span="12" align="center">承诺办结时限</el-col>
          </el-row>
        </el-card>
        <el-card class="guide-card card-text" shadow="never">
          <template v-slot:header>
            <h3>办事指南评价</h3>
            <el-row>
              <el-col :span="4" align="left"> 评分</el-col>
              <el-col :span="20" align="left"
                ><el-rate
                  disabled
                  v-model="matterDetailsObj.preServiceExt.starLevel"
                ></el-rate
              ></el-col>
            </el-row>
          </template>
          <div>
            <el-button type="primary">我要评价</el-button>
            <el-button type="primary">查看评价</el-button>
          </div>
        </el-card>
        <el-card class="guide-card card-text" shadow="never">
          <div class="borderDashed">
            <h4>办理形式</h4>
            <p>{{ matterDetailsObj.preServiceExt.handleType }}</p>
          </div>
          <div class="borderDashed">
            <h4>纸质申请材料收取方式</h4>
            <p>{{ matterDetailsObj.preService.applyAttrGettype }}</p>
          </div>
          <div class="borderDashed">
            <h4>纸质申请材料邮递的收件人信息</h4>
            <p>{{ matterDetailsObj.preService.applyAttrGetinfo }}</p>
          </div>
          <div class="borderDashed">
            <h4>办理地点</h4>
            <p>{{ matterDetailsObj.preService.acceptAddrDesc }}</p>
          </div>
          <div class="borderDashed">
            <h4>办公时间</h4>
            <p>
              {{ matterDetailsObj.preService.officeTime }}
            </p>
          </div>
        </el-card>
        <el-card class="guide-card card-text" shadow="never">
          <div style="padding: 10px 6px">
            <h4>指南下载与分享</h4>
            <el-button type="text" icon="el-icon-download"
              >下载至本地</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import serviceGuideContent from "./serviceGuideContent";

const NOW = new Date();

export default {
  name: "guide",
  components: {
    serviceGuideContent,
  },
  data() {
    return {
      navFlag:"",//面包屑导航显示
      offsetTop: 28,
      collectItemId: "",
      guideId: "",
      starNum: 5,
      matterObj: {}, //事项信息
      matterUnid: "", //事项unid
      matterDetailsObj: {}, //事项详情信息
      linkType:false,
    };
  },
  computed: {},

  created() {
    this.matterObj = JSON.parse(sessionStorage.getItem("matterRow"));
    let unid = JSON.parse(sessionStorage.getItem("matterunid"));
    let matterChooseType = sessionStorage.getItem("matterChooseType");
    if (matterChooseType == "1") {
      this.matterUnid = this.matterObj.unid;
    } else {
      this.matterUnid = unid;
    }
    if(this.$route.params.linkType){
      this.linkType=this.$route.params.linkType
    }
    this.getMatterDetailsList(this.matterUnid);
    this.navFlag = sessionStorage.getItem("titleType")
  },
  mounted() {},
  methods: {
    // 获取事项详细信息
    getMatterDetailsList(unid) {
      let url = "/preService/service/getPreServiceDetailedInfo/" + unid;
      let that = this;
      this.$httpApi
        .get(url)
        .then((r) => {
          that.matterDetailsObj = r.data;
        })
        .catch((e) => {});
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.policy-guide {
  padding-top: 20px;
}
.wrap-header {
  padding-bottom: 10px;
  border-bottom: 1px solid $col-separator;
  margin-bottom: 20px;

  .policy-name {
    margin-bottom: 10px;
    text-align: center;
    font-weight: normal;
    color: #000000;
  }
  .header_bottom {
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
  }
  .policy-infos {
    flex: 1;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .policy-info-item {
    display: -webkit-flex;
    display: flex;
    width: 350px;

    .info-label {
      margin-right: 5px;
    }
    .info-value {
      flex: 1;
    }
  }
  .guide-btns {
    ::v-deep .el-button {
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      vertical-align: middle;
    }
    .btn-collect {
      width: 100px;

      .collect_inner {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .collect-icon,
      .collect-text {
        vertical-align: middle;
      }

      .collect-icon {
        font-size: 18px;

        &.el-icon-star-on {
          color: $col-yellow;
        }
      }
    }
  }
}
.wrap-content {
  display: -moz-grid;
  display: -ms-grid;
  display: grid;
  grid-template-columns: calc(100% - 250px - 20px) auto;

  .content_left {
    -ms-grid-column: 1;
  }
  .content_right {
    -ms-grid-column: 2;
    width: 250px;
    margin-left: 20px;
  }
}
.guide-version {
  display: -webkit-flex;
  display: flex;
  align-items: center;

  h2 {
    margin-right: 20px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
      "微软雅黑", Arial, sans-serif;
    color: #000000;
  }
}
::v-deep .guide-card {
  margin-bottom: 20px;

  .el-card__header {
    padding: 10px;
    text-align: center;

    i {
      margin-right: 5px;
    }
  }
  .el-card__body {
    padding: 10px;
  }
}
::v-deep .card-text {
  position: relative;

  .card-text-content {
    position: relative;

    a {
      text-decoration: underline $col-theme;
      color: $col-theme;
    }
    .content-type {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      margin-top: 10px;
      color: #ffffff;
      background-color: $col-theme;
    }
  }

  .card-text-footer {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    color: #999999;

    .card-text-jg {
      flex: 1;
      padding-right: 10px;
    }
  }
}
::v-deep .card-question {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 99;

  .question-content-item + .question-content-item {
    margin-top: 20px;
  }
  .contentItem-section {
    display: -webkit-flex;
    display: flex;

    .section-label {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background-color: $col-theme;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 5px solid transparent;
        border-bottom: 2px solid $col-theme;
        border-left: 5px solid transparent;
      }

      &.section-label-a {
        background-color: $col-green;

        &::before {
          border-bottom: 2px solid $col-green;
        }
      }
    }
    .section-value {
      flex: 1;
    }
    .section-value-q {
      color: #727475;
    }
    .section-value-a {
      color: #727475;
    }
  }
  .contentItem-section + .contentItem-section {
    margin-top: 5px;
  }
}

.policy-info {
  flex: 1;
  display: -moz-grid;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  gap: 10px 10px;
  color: #ffffff;

  .policy-info-item {
    display: -webkit-flex;
    display: flex;

    .infoItem-label {
      margin-right: 5px;
    }
    .infoItem-value {
      flex: 1;
    }
  }
}
.borderDashed {
  border-bottom: 1px dashed #ccc;
  min-height: 80px;
  padding: 10px 8px;
}
</style>
